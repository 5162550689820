<script setup lang="ts">
import { inject, onMounted, onUnmounted, ref } from "vue";

import { useRouter } from "vue-router";
import Base from "@/components/UI/templates/DataCapturePageTemplate.vue";
import MainFooter from "@/components/UI/atoms/MainFooter.vue";

import IconElectric from "@/assets/icons/IconElectric.vue";
import IconGas from "@/assets/icons/IconGas.vue";

import { errorMessages } from "@/helpers/errorMessages";
import { useEnquiryStore } from "@/stores/enquiry";
import { useJourneyConfig } from "@/composables/useJourneyConfig";
import {
  useAnalyticsClient,
  EVENT_CHECKBOX,
  PLATFORM_JOURNEY,
  JOURNEY_ENERGY,
} from "@/composables/useAnalyticsClient";
import {
  SERVICE_ELECTRICITY,
  SERVICE_GAS,
  INTENT_SOMETHING_ELSE,
  PAGE_INTENT,
  PAGE_NAME,
  PAGE_SERVICE,
  TRACKING_HANDOVER_SOMETHING_ELSE,
  TRACKING_DUAL_FUEL,
  PAGE_MULTI_PREMISE,
  EXPERIMENT_ER_QUESTION_SWAP,
} from "@/helpers/constants";
import { useAssistantStore } from "@/stores/assistant";
import { AssistantMood } from "@/types/Assistants";
import { useEventProcessorService } from "@/composables/useEventProcessorService";
import {
  CheckBoxCards,
  useDelay,
  Constants,
} from "@makeitcheaper/rpp-frontend";
import AssistantToastMessage from "../molecules/AssistantToastMessage.vue";
import { AwsRumServiceKey } from "@/helpers/injectKeys";
import { PartnerCodes } from "@makeitcheaper/rpp-lib";
import { isSplitTestVariant } from "@/composables/useEvidentlySplitTest";

const enquiry = useEnquiryStore();
const router = useRouter();
const assistant = useAssistantStore();
const config = useJourneyConfig();
const analyticsClient = useAnalyticsClient();
const eventProcessorService = useEventProcessorService();
const awsRumService = inject(AwsRumServiceKey);

const { INFO } = Constants;

const heading = ref([] as string[]);
const localState = ref(enquiry.productPayload.servicesRequired || []);
const errorMessage = ref("");
const pending = ref(false);
const displayContent = ref(false);
const displayFooter = ref(false);
const displayButtons = ref(false);
const displayToastMessage = ref(false);

const triggerGAJourneyStart = () =>
  analyticsClient.trackJourneyStarter(JOURNEY_ENERGY);

const triggerGATrackNext = () => analyticsClient.trackNext(PAGE_SERVICE);

const triggerGASelectService = () => {
  analyticsClient.trackCustomInteraction(
    EVENT_CHECKBOX,
    PAGE_SERVICE,
    localState.value.length === 2
      ? TRACKING_DUAL_FUEL
      : localState.value.toString(),
    PLATFORM_JOURNEY,
  );
};

const triggerGASomethingElse = () =>
  analyticsClient.trackNext(PAGE_SERVICE, TRACKING_HANDOVER_SOMETHING_ELSE);

const cardData = [
  {
    value: SERVICE_ELECTRICITY,
    body: {
      icon: IconElectric,
      heading: "Electricity",
      content: "A range of electricity suppliers for your power needs.",
    },
  },
  {
    value: SERVICE_GAS,
    body: {
      icon: IconGas,
      heading: "Gas",
      content: "Business gas options for heating and operational needs",
    },
  },
];

const toastText = "Not here for a comparison? Let me know if you ";
const toastHyperlink = "need help with something else.";

const handleNextClick = async () => {
  errorMessage.value = "";

  if (!localState.value.length) {
    errorMessage.value = errorMessages.emptyOption;
    return;
  }

  pending.value = true;

  handleChange(localState.value);

  triggerGASelectService();
  triggerGATrackNext();

  await eventProcessorService.updateEnquiry(enquiry.$state);

  // ET-2762 Temp split test for ERQuestionSwapTest experiment
  router.push(
    isSplitTestVariant(enquiry, EXPERIMENT_ER_QUESTION_SWAP)
      ? PAGE_MULTI_PREMISE
      : PAGE_NAME,
  );
};

const handleBackClick = async () => {
  analyticsClient.trackBack(PAGE_SERVICE);
  router.push(PAGE_INTENT);
};

function handleChange(selectedValues: Array<string>) {
  errorMessage.value = "";
  localState.value = selectedValues;

  enquiry.productPayload.servicesRequired = localState.value;
  enquiry.productPayload.enquiryIntent = undefined;
  enquiry.productPayload.call20 = true;
}

async function handleSomethingElse() {
  triggerGASomethingElse();

  enquiry.productPayload.enquiryIntent = INTENT_SOMETHING_ELSE;
  enquiry.productPayload.servicesRequired = [];
  enquiry.productPayload.call20 = false;

  await eventProcessorService.updateEnquiry(enquiry.$state);

  router.push(PAGE_NAME);
}

onMounted(async () => {
  awsRumService?.createRumEvent("JourneyStart", {
    enquiryId: eventProcessorService?.enquiryGuid,
    campaignGUID: eventProcessorService?.campaignGUID,
    partnerName: eventProcessorService?.partnerName,
    testLead: enquiry.trackingPayload.testLead,
  });
  triggerGAJourneyStart();

  assistant.setMood(AssistantMood.NEUTRAL);

  await useDelay(700);
  heading.value.push(
    config?.partner === PartnerCodes.CTM
      ? "Hi. I'm here to help!"
      : "Hi. I'm Bertie, here to help!",
  );

  await useDelay(700);
  heading.value.push("Which services are you looking to compare today?");
  assistant.setMood(AssistantMood.EXCITED);

  await useDelay(500);
  displayContent.value = true;

  await useDelay(400);
  displayButtons.value = true;

  await useDelay(300);
  displayToastMessage.value = true;

  await useDelay(200);
  displayFooter.value = true;
});
onUnmounted(() => {
  assistant.setMood(AssistantMood.NEUTRAL);
});
</script>
<template>
  <div>
    <Base
      :heading="heading"
      :displayBack="false"
      :displayNext="displayButtons"
      :next-is-disabled="!!errorMessage.length"
      :error-message="errorMessage"
      :is-loading="pending"
      @click="handleNextClick"
      @back="handleBackClick"
    >
      <CheckBoxCards
        v-if="displayContent"
        :data="cardData"
        :local-state="localState"
        @change="handleChange"
      />
    </Base>
    <Transition name="slide-up">
      <AssistantToastMessage
        v-if="displayToastMessage"
        :partner="config?.partner"
        :toast-text="toastText"
        :toastHyperlink="toastHyperlink"
        @hyperlink="handleSomethingElse"
        :theme="INFO"
      />
    </Transition>
    <Transition name="slide-up">
      <MainFooter v-if="displayFooter" />
    </Transition>
  </div>
</template>

<style scoped lang="scss">
.Card__Content,
.Card__Heading {
  padding-right: 0;
}

.MainFooter {
  margin-top: var(--spacing--40);
  margin-bottom: var(--spacing--40);
}
</style>
